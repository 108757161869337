<script setup>
import OnScrollIn from '@/Components/Animations/OnScrollIn.vue'

const props = defineProps({
    imgSrc: {
        type: String,
        default: '',
    },
    animate: {
        type: Boolean,
        default: true,
    },
    threshold: {
        type: Number,
        default: 0.2,
    },
})
</script>

<template>
    <OnScrollIn
        :fade="props.animate"
        :translate="props.animate"
        :threshold="props.threshold"
        class="isolate mx-auto max-w-7xl overflow-hidden rounded-xl bg-cover bg-center px-6 py-14 ring-1 ring-slate-200 sm:px-10 sm:py-24 lg:py-24 xl:px-24"
        :style="{ backgroundImage: 'url(' + props.imgSrc + ')' }"
    >
        <slot>Container Slot</slot>
    </OnScrollIn>
</template>
