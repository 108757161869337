<script setup>
import { useIntersectionObserver } from '@vueuse/core'
import { ref, defineProps, watch } from 'vue'

const props = defineProps({
    // duration: {
    //     type: Number,
    //     default: 700,
    // },
    once: {
        type: Boolean,
        default: true,
    },
    threshold: {
        type: Number,
        default: 0.2,
    },
    translate: {
        type: Boolean,
        default: false,
    },
    fade: {
        type: Boolean,
        default: false,
    },
})

let transitionClass = 'transition ease-in duration-300'
let visibleClass = ''
let invisibleClass = ''

if (props.fade) {
    visibleClass += ' opacity-100 '
    invisibleClass += ' opacity-0 '
}

if (props.translate) {
    transitionClass += ' transform '
    visibleClass += ' translate-y-0 '
    invisibleClass += ' translate-y-4 '
}

const target = ref(null)
const targetIsVisible = ref(false)

const { stop } = useIntersectionObserver(
    target,
    ([{ isIntersecting }]) => {
        targetIsVisible.value = isIntersecting
    },
    {
        threshold: props.threshold,
    }
)

if (props.once) {
    watch(targetIsVisible, () => {
        stop()
    })
}
</script>

<template>
    <div ref="target" :class="[transitionClass, targetIsVisible ? visibleClass : invisibleClass]">
        <slot></slot>
    </div>
</template>
